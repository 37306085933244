import React from 'react'
import styled from 'styled-components'

const HR = styled.div`
  height: 0;
  border-bottom: 1px solid black;
  flex: 1;
`
const Container = styled.div`
  display: flex;
  align-items: center;
`
const Label = styled.p`
  padding-right: 1rem;
`

export default function StoryblokSeparator({ data, style }) {
  return (
    <Container style={style}>
      {data?.label && <Label>{data.label}</Label>}
      <HR />
    </Container>
  )
}
